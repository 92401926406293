import React from 'react'

export default function NotFound() {
  return (
    <div>
        <h2>404</h2>
        <p>Sorry that page does not exist.</p>
    </div>
  )
}
